import * as React from "react"
import { graphql, Link, navigate } from "gatsby"
import Layout from "../../../content/Layout"
import isEqual from "lodash.isequal"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { StoreContext } from "../../../context/store-context"
import { AddToCart } from "../../../components/add-to-cart"
import { NumericInput } from "../../../components/numeric-input"
import { formatPrice } from "../../../utils/format-price"
import SEO from "../../../content/Seo"
import SocialLinks from "../../../content/SocialLinks"
import { CgChevronRight as ChevronIcon } from "react-icons/cg"
import {
  productBox,
  container,
  header,
  productImageWrapper,
  productImageList,
  productImageListItem,
  scrollForMore,
  noImagePreview,
  optionsWrapper,
  priceValue,
  selectVariant,
  labelFont,
  breadcrumb,
  tagList,
  addToCartStyle,
  metaSection,
  productDescription,
} from "./product-page.module.css"
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md"

import Featured from "../../../content/Featured"
import { Disclosure, RadioGroup, Tab } from "@headlessui/react"
import { HeartIcon, MinusSmIcon, PlusSmIcon } from "@heroicons/react/outline"
import CircleFill from "../../../assets/icons/circleFill"

const details = [
  {
    name: "Details & Size",
    icon: (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="7"
          cy="7"
          r="6"
          fill="#0D3552"
          stroke="#0D3552"
          stroke-width="2"
        />
      </svg>
    ),
    items: [
      "Multiple strap configurations",
      "Spacious interior with top zip",
      "Leather handle and tabs",
      "Interior dividers",
      "Stainless strap loops",
      "Double stitched construction",
      "Water-resistant",
    ],
  },
  {
    name: "Reviews",
    icon: (
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.49759 6.67043L8.90742 1L12.3173 6.67043H5.49759Z"
          fill="#0D3552"
        />
        <path
          d="M13.0656 8.83648H4.67213L1 15H17L13.0656 8.83648Z"
          fill="#0D3552"
        />
        <path
          d="M5.49759 6.67043L8.90742 1L12.3173 6.67043H5.49759Z"
          stroke="#0D3552"
        />
        <path
          d="M13.0656 8.83648H4.67213L1 15H17L13.0656 8.83648Z"
          stroke="#0D3552"
        />
      </svg>
    ),
    items: [
      "Multiple strap configurations",
      "Spacious interior with top zip",
      "Leather handle and tabs",
      "Interior dividers",
      "Stainless strap loops",
      "Double stitched construction",
      "Water-resistant",
    ],
  },
  {
    name: "Shipping & Returns",
    icon: (
      <svg
        width="19"
        height="16"
        viewBox="0 0 19 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.5 2L16.8612 14.75H2.13878L9.5 2Z"
          fill="#0D3552"
          stroke="#0D3552"
          stroke-width="2"
        />
      </svg>
    ),
    items: [
      "Multiple strap configurations",
      "Spacious interior with top zip",
      "Leather handle and tabs",
      "Interior dividers",
      "Stainless strap loops",
      "Double stitched construction",
      "Water-resistant",
    ],
  },
  // More sections...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Product({ data: { product, suggestions } }) {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    description,
    descriptionHtml,
    images,
    images: [firstImage],
  } = product
  const { client } = React.useContext(StoreContext)

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleOptionChange = (index, event) => {
    const value = event.target.value

    if (value === "") {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const hasVariants = variants.length > 1
  const hasImages = images.length > 0
  const hasMultipleImages = true || images.length > 1

  const structuredData = {
    "@context": "https://schema.org",
    "@type": ["Product", "Car"],
    name: title,
    brand: {
      "@type": "Brand",
      name: "Elemental Connection",
    },
    image: getSrc(firstImage.gatsbyImageData),
    description: description,
    offers: {
      "@type": "Offer",
      url: `https://elementalconnection.com.au/products/${product.productType}/${product.handle}`,
      price: price,
      priceCurrency: "AUD",
      itemCondition: "https://schema.org/NewCondition",
      availability: "https://schema.org/InStock",

    },
  }

  return (
    <Layout>
      {firstImage ? (
        <SEO
          title={title}
          description={description}
          image={getSrc(firstImage.gatsbyImageData)}
        />
      ) : undefined}
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      <main className="max-w-screen-2xl mx-auto pt-24 pb-12 sm:px-6 lg:px-8">
        <div className={container}>
          <div className="flex justify-between py-6 pb-12 ">
            <button
              className="flex items-center gap-2"
              onClick={() => navigate(-1)}
            >
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 7L15 1.80385V12.1962L3 7Z"
                  fill="#0D3552"
                  stroke="#0D3552"
                  stroke-width="2"
                />
              </svg>

              <h4 className="font-heading text-ec-sea text-lg hover:font-semibold transition ease-in-out">
                BACK
              </h4>
            </button>
            <SocialLinks
              url={`https://elementalconnection.com.au/products/${product.productType}/${title}`}
              title={title}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 ">
            {hasImages && (
              <div className={productImageWrapper}>
                <Tab.Group as="div" className="flex flex-col-reverse">
                  {/* Image selector */}
                  <div className=" mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
                    <Tab.List className="grid grid-cols-4 gap-6">
                      {images.map((image, index) => (
                        <Tab
                          key={image.id}
                          className="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none"
                        >
                          {({ selected }) => (
                            <>
                              <span className="sr-only">{image.name}</span>
                              <span className="ring-ec-earth absolute inset-0 rounded-md overflow-hidden">
                                <GatsbyImage
                                  objectFit="contain"
                                  loading={index === 0 ? "eager" : "lazy"}
                                  alt={
                                    image.altText
                                      ? image.altText
                                      : `Product Image of ${title} #${
                                          index + 1
                                        }`
                                  }
                                  image={image.gatsbyImageData}
                                  className="w-full object-center w-full h-full object-cover sm:rounded-lg ring-ec-earth"
                                />
                              </span>
                              <span
                                className={classNames(
                                  selected
                                    ? "ring-ec-earth"
                                    : "ring-transparent",
                                  "absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none"
                                )}
                                aria-hidden="true"
                              />
                            </>
                          )}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>

                  <Tab.Panels className="w-full aspect-w-1 aspect-h-1">
                    {images.map((image, index) => (
                      <Tab.Panel key={image.id}>
                        <GatsbyImage
                          objectFit="contain"
                          loading={index === 0 ? "eager" : "lazy"}
                          alt={
                            image.altText
                              ? image.altText
                              : `Product Image of ${title} #${index + 1}`
                          }
                          image={image.gatsbyImageData}
                          className="rounded-tr-[100px] rounded-bl-[100px]"
                        />
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>
                {hasMultipleImages && (
                  <div className={scrollForMore} id="instructions">
                    <span aria-hidden="true">←</span> scroll for more{" "}
                    <span aria-hidden="true">→</span>
                  </div>
                )}
              </div>
            )}
            {!hasImages && (
              <span className={noImagePreview}>No Preview image</span>
            )}
            <div>
              <div className={breadcrumb}>
                <Link to={`/catalogue?p=${product.productType}`}>
                  {product.productType}
                </Link>
                <ChevronIcon size={12} />
              </div>
              <h1 className="text-3xl font-heading text-ec-earth py-6">
                {title}
              </h1>
              <div 
                dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                className="text-lg text-ec-sea font-heading space-y-6"
                id="productDescription"
              ></div>
              <h2 className="text-3xl font-heading text-ec-sea py-6">
                <span>{price}</span>
              </h2>
              <fieldset className={optionsWrapper}>
                {hasVariants &&
                  options.map(({ id, name, values }, index) => (
                    <div
                      className="bg-transparent border-2 border-ec-earth cursor-pointer rounded-md h-12 min-w-[24ch] relative mb-6"
                      key={id}
                    >
                      <MdArrowDropDown className="absolute w-6 h-6 top-[10px] text-[#78757a] right-4" />

                      <select
                        aria-label="Variants"
                        onChange={(event) => handleOptionChange(index, event)}
                        className="bg-transparent w-full h-full cursor-pointer text-ec-earth text-lg px-4 focus-visible:outline-none font-heading appearance-none"
                      >
                        <option value="">{`Select ${name}`}</option>
                        {values.map((value) => (
                          <option
                            value={value}
                            key={`${name}-${value}`}
                            className="text-ec-sea"
                          >
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
              </fieldset>
              <div className="flex gap-4 items-center ">
                <NumericInput
                  aria-label="Quantity"
                  onIncrement={() => setQuantity((q) => Math.min(q + 1, 20))}
                  onDecrement={() => setQuantity((q) => Math.max(1, q - 1))}
                  onChange={(event) => setQuantity(event.currentTarget.value)}
                  value={quantity}
                  min="1"
                  max="20"
                />
                <AddToCart
                  variantId={productVariant.storefrontId}
                  quantity={quantity}
                  available={available}
                />
              </div>
              <div className="flex flex-wrap flex-col pt-6">
                <div className=" flex items-center pb-2">
                  {" "}
                  <div className={labelFont}>In Stock</div>
                  <div className={tagList}>
                    <a>{product.totalInventory}</a>
                  </div>
                </div>
                <div className=" flex items-center pb-2">
                  {" "}
                  <div className={labelFont}>Weight</div>
                  <div className={tagList}>
                    <a>{product.variants[0].weight} kg</a>
                  </div>
                </div>
                <div className=" flex items-center pb-2">
                  {" "}
                  <div className={labelFont}>Category</div>
                  <div className={tagList}>
                    <Link to={`/catalogue?p=${product.productType}`}>
                      {product.productType}
                    </Link>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className={labelFont}>Tags</div>
                  <div className={tagList}>
                    {product.tags.map((tag) => (
                      <Link to={`/catalogue?t=${tag}`}>{tag}</Link>
                    ))}
                  </div>
                </div>
              </div>
              <section aria-labelledby="details-heading" className="mt-12">
                <h2 id="details-heading" className="sr-only">
                  Additional details
                </h2>

                <div className="border-t divide-y-2 divide-white bg-ec-sky/40 rounded-sm">
                  <Disclosure as="div">
                    {({ open }) => (
                      <>
                        <h3>
                          <Disclosure.Button className="group relative w-full p-4 flex justify-between items-center text-left">
                            <span
                              className={classNames(
                                open ? "font-semibold" : "font-medium",
                                "text-md text-ec-sea "
                              )}
                            >
                              <div className="flex gap-2 justify-center items-center capitalize">
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="7"
                                    cy="7"
                                    r="6"
                                    fill="#0D3552"
                                    stroke="#0D3552"
                                    stroke-width="2"
                                  />
                                </svg>
                                <p>We Care About Sustainability </p>
                              </div>
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusSmIcon
                                  className="block h-6 w-6 text-ec-sea"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusSmIcon
                                  className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel
                          as="div"
                          className="pb-6 prose prose-sm bg-ec-air border-t-2 border-white p-4"
                        >
                          <p className="font-body text-gray-700">
                            We have made it our mission to get crystals to your
                            door with as little harm to the environment as
                            possible. Whether that’s through biodegradable,
                            non-plastic bubble wrap to protect your gems or gift
                            cards made from recycled materials, we are always
                            looking for new ways to reduce our environmental
                            impact.{" "}
                          </p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure as="div">
                    {({ open }) => (
                      <>
                        <h3>
                          <Disclosure.Button className="group relative w-full p-4 flex justify-between items-center text-left">
                            <span
                              className={classNames(
                                open ? "font-semibold" : "font-medium",
                                "text-md text-ec-sea "
                              )}
                            >
                              <div className="flex gap-2 justify-center items-center">
                                <svg
                                  width="18"
                                  height="16"
                                  viewBox="0 0 18 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.49759 6.67043L8.90742 1L12.3173 6.67043H5.49759Z"
                                    fill="#0D3552"
                                  />
                                  <path
                                    d="M13.0656 8.83648H4.67213L1 15H17L13.0656 8.83648Z"
                                    fill="#0D3552"
                                  />
                                  <path
                                    d="M5.49759 6.67043L8.90742 1L12.3173 6.67043H5.49759Z"
                                    stroke="#0D3552"
                                  />
                                  <path
                                    d="M13.0656 8.83648H4.67213L1 15H17L13.0656 8.83648Z"
                                    stroke="#0D3552"
                                  />
                                </svg>
                                Shipping & Returns
                              </div>
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusSmIcon
                                  className="block h-6 w-6 text-ec-sea"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusSmIcon
                                  className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel
                          as="div"
                          className="pb-6 prose prose-sm bg-ec-air border-t-2 border-white p-4"
                        >
                          <p className="font-body text-gray-700 pb-2 font-bold">
                            FREE Shipping on all orders over $100.00
                          </p>
                          <p className="font-body text-gray-700 pb-2">
                            We do our best to get your new order shipped out
                            within 3 business days. If we are experiencing
                            delays, we will be sure to let you know in advance.
                            If you need to get in contact about your order,
                            please direct all correspondence to{" "}
                            <a
                              href="mailto:deanne@elementalconnection.com.au"
                              className="underline font-italic"
                            >
                              this email.
                            </a>
                          </p>
                          <p className="font-body text-gray-700 pb-2">
                            <Link
                              to="/policy"
                              className="underline font-italic"
                            >
                              Click here
                            </Link>{" "}
                            to view our complete Shipping & Returns Policy.
                          </p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure as="div">
                    {({ open }) => (
                      <>
                        <h3>
                          <Disclosure.Button className="group relative w-full p-4 flex justify-between items-center text-left">
                            <span
                              className={classNames(
                                open ? "font-semibold" : "font-medium",
                                "text-md text-ec-sea "
                              )}
                            >
                              <div className="flex gap-2 justify-center items-center">
                                <svg
                                  width="19"
                                  height="16"
                                  viewBox="0 0 19 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.5 2L16.8612 14.75H2.13878L9.5 2Z"
                                    fill="#0D3552"
                                    stroke="#0D3552"
                                    stroke-width="2"
                                  />
                                </svg>
                                Get 10% Off your next order
                              </div>
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusSmIcon
                                  className="block h-6 w-6 text-ec-sea"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusSmIcon
                                  className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel
                          as="div"
                          className="pb-6 prose prose-sm bg-ec-air border-t-2 border-white p-4"
                        >
                          <p className="font-body text-gray-700 pb-2">
                            For a limited time, you can receive up to 10% off
                            your next batch of crystals just by flaunting your
                            favourite Elemental Connection purchase!
                          </p>
                          <p className="font-body text-gray-700 pb-2">
                            Simply take a pic with your crystals when they
                            arrive and tag <a className="underline text-gray-500 hover:text-gray-700" target="blank" href="https://www.instagram.com/elementalconnection/">@elementalconnection</a> on either
                            Facebook or Instagram to be eligible.
                          </p>
                          <p className="font-body text-gray-700 pb-2">
                            Follow our socials for more details and discounts
                            across our growing product range.
                          </p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              </section>
            </div>
          </div>
        </div>

        <section aria-labelledby="related-heading" className="mt-16 sm:mt-24">
          <div className="text-3xl mx-auto text-center pb-24">
            <h2 className="px-4 font-heading text-ec-earth pb-2">
              Brand New Products
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="334"
              height="12.219"
              viewBox="0 0 334 12.219"
              className="mx-auto"
            >
              <g
                id="Feather_line"
                data-name="Feather line"
                transform="translate(487.997 -950.5) rotate(90)"
              >
                <line
                  id="Line_15"
                  data-name="Line 15"
                  y1="124.003"
                  transform="translate(957.5 153.997)"
                  fill="none"
                  stroke="#a85e36"
                  stroke-width="2"
                />
                <line
                  id="Line_16"
                  data-name="Line 16"
                  y1="124.997"
                  transform="translate(957.5 363)"
                  fill="none"
                  stroke="#a85e36"
                  stroke-width="2"
                />
                <ellipse
                  id="Ellipse_13"
                  data-name="Ellipse 13"
                  cx="5.841"
                  cy="5.841"
                  rx="5.841"
                  ry="5.841"
                  transform="translate(951.038 290.453)"
                  fill="#a85e36"
                />
                <ellipse
                  id="Ellipse_14"
                  data-name="Ellipse 14"
                  cx="5.841"
                  cy="5.841"
                  rx="5.841"
                  ry="5.841"
                  transform="translate(951.038 339.95)"
                  fill="#a85e36"
                />
                <path
                  id="Polygon_9"
                  data-name="Polygon 9"
                  d="M7,0l7,12H0Z"
                  transform="translate(950.5 328.497) rotate(-90)"
                  fill="#a85e36"
                />
              </g>
            </svg>
            <Featured />
          </div>

          {/* <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {relatedProducts.map((relatedProduct) => (
              <div key={relatedProduct.id} className="group relative">
                <div className="w-full min-h-80 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                  <img
                    src={relatedProduct.imageSrc}
                    alt={relatedProduct.imageAlt}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                  />
                </div>
                <div className="mt-4 flex justify-between">
                  <div>
                    <h3 className="text-sm text-gray-700">
                      <a href={relatedProduct.href}>
                        <span aria-hidden="true" className="absolute inset-0" />
                        {relatedProduct.name}
                      </a>
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {relatedProduct.color}
                    </p>
                  </div>
                  <p className="text-sm font-medium text-gray-900">
                    {relatedProduct.price}
                  </p>
                </div>
              </div>
            ))}
          </div> */}
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!, $productType: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      title
      handle
      description
      descriptionHtml
      totalInventory
      productType
      productTypeSlug: gatsbyPath(
        filePath: "/products/{ShopifyProduct.productType}"
      )
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      images {
        # altText
        id
        gatsbyImageData(layout: CONSTRAINED, width: 640, aspectRatio: 1)
      }
      variants {
        availableForSale
        storefrontId
        title
        price
        weight
        selectedOptions {
          name
          value
        }
      }
      options {
        name
        values
        id
      }
    }
    suggestions: allShopifyProduct(
      limit: 3
      filter: { productType: { eq: $productType }, id: { ne: $id } }
    ) {
      nodes {
        ...ProductCard
      }
    }
  }
`

