import * as React from "react"
import { StoreContext } from "../context/store-context"
import { addToCart as addToCartStyle } from "./add-to-cart.module.css"

export function AddToCart({ variantId, quantity, available, ...props }) {
  const { addVariantToCart, loading } = React.useContext(StoreContext)

  function addToCart(e) {
    e.preventDefault()
    addVariantToCart(variantId, quantity)
    window.gtag("event", "add_to_cart", {
      event_category: "cart",
      event_label: "add_to_cart",
    })
  }

  return (
    <button
      type="submit"
      className="max-w-xs flex-1 bg-ec-earth border-2 border-ec-earth rounded-full py-3 px-8 flex items-center justify-center text-lg font-heading text-white hover:bg-transparent hover:text-ec-earth transition ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-ec-earth sm:w-full"
      onClick={addToCart}
      disabled={!available || loading}
      {...props}
    >
      {available ? "Add to Cart" : "Out of Stock"}
    </button>
  )
}
